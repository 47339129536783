import React, {FC} from 'react';
import PageBuilder from '../../features/page-builder';
import {useAboutData} from '../../hooks/db/useAboutData';
import {AboutProps} from './types';

const About: FC<AboutProps> = () => {
  const pageData = useAboutData();

  return (
    <PageBuilder component={pageData}/>
  );
};

export default About;