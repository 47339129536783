import {Pagination, Stack} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, {FC, ReactElement, useEffect, useState} from 'react';
import {StyledPaginationGridProps} from './types';

const StyledPaginationGrid: FC<StyledPaginationGridProps> = ({maxItems, gridSize, children}) => {
  const [grids, setGrids] = useState<ReactElement[]>([]);
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    const childrenArray = React.Children.toArray(children);
    const grids: ReactElement[] = [];

    // TODO fix keys
    for (let i = 0; i < childrenArray.length; i += maxItems ?? 1) {
      const grid = (
        <Grid2 container spacing={1} key={'arsnteirneast' + i}>
          {
            childrenArray.slice(i, i + (maxItems ?? 1))
                         .map(child =>
                           <Grid2 xs={gridSize}
                                  key={'qwfneiqnweif ' + Math.random()
                                                             .toString()}
                           >
                             {child}
                           </Grid2>,
                         )
          }
        </Grid2>
      );

      grids.push(grid);
    }

    setGrids(grids);
  }, [children, gridSize, maxItems]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageIndex(value);
  };

  return (
    <Stack spacing={1} width={1} height={1} justifyContent={'space-between'} paddingBottom={4}>
      {
        grids[pageIndex - 1]
      }
      <Pagination variant={'outlined'}
                  page={pageIndex}
                  onChange={handleChange}
                  count={grids.length ?? 1}
                  shape={'rounded'}
                  sx={{alignSelf: 'center'}}
      />
    </Stack>
  );
};

export default StyledPaginationGrid;