import {Tooltip, Typography} from '@mui/material';
import React, {FC, useEffect, useRef, useState} from 'react';
import {EllipsisTypographyProps} from './types';

const EllipsisTypography: FC<EllipsisTypographyProps> = (props) => {
  const {fontSize, content, lines, characters} = props;
  const ref = useRef<HTMLElement>(null);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipToggleable, setTooltipToggleable] = useState(false);

  useEffect(() => {
    const refElement = ref.current;
    if (refElement && content) {
      refElement.innerHTML = content;

      if (lines) {
        setTooltipToggleable(true);

        const lineHeight = getComputedStyle(refElement).lineHeight;
        const maxHeight = Number.parseFloat(lineHeight) * lines;

        let text = content;
        while (refElement.offsetHeight > maxHeight) {
          text = text.slice(0, -1);
          refElement.innerHTML = text + '...';
        }
      }

      if (characters && content.length > characters) {
        setTooltipToggleable(true);
        refElement.innerHTML = content.substring(0, characters) + '...';
      }
    }
  }, [content, lines, characters]);

  const handleTooltipOpen = () => {
    if (tooltipToggleable) {
      setIsTooltipOpen(true);
    }
  };

  const handleTooltipClose = () => {
    if (tooltipToggleable) {
      setIsTooltipOpen(false);
    }
  };

  // TODO can i pass through props to StyledTypography instead?
  return (
    <Tooltip title={content} followCursor open={isTooltipOpen} onOpen={handleTooltipOpen} onClose={handleTooltipClose}>
      <Typography ref={ref} fontSize={fontSize ?? 14}>
        {'Placeholder'}
      </Typography>
    </Tooltip>
  );
};

export default EllipsisTypography;