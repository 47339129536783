import {CssBaseline, ThemeProvider} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {SnackbarProvider} from 'notistack';
import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {RouterProvider} from 'react-router-dom';
import {FirebaseAuthProvider} from '../contexts/FirebaseAuthContext';
import {appRouter} from '../routes/router';
import {theme} from './theme';

export default function App() {
  const queryClient = new QueryClient({
    // https://tanstack.com/query/latest/docs/react/reference/QueryClient
    // defaultOptions: {
    //   queries: {
    //     // cacheTime: Infinity
    //   }
    // }
  });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider dense maxSnack={3} autoHideDuration={5000} disableWindowBlurListener>
          <FirebaseAuthProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CssBaseline/>
              <RouterProvider router={appRouter}/>
              <ReactQueryDevtools/>
            </LocalizationProvider>
          </FirebaseAuthProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

