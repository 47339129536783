import {Stack} from '@mui/material';
import React, {FC} from 'react';
import {generateUniqueID} from 'web-vitals/dist/modules/lib/generateUniqueID';
import {
  EmailComponentModel,
  SearchComponentModel,
  StyledBodyComponentModel,
  TitledComponentModel,
} from '../../models/BasicPackageLandingPageModel';
import PageDirectorySearchSection from './page-directory-search-section';
import PageEmailSubscribeSection from './page-email-subscribe-section';
import PageStyledBodySection from './page-styled-body-section';
import PageTitledSection from './page-titled-section';
import {PageBuilderProps} from './types';

const PageBuilder: FC<PageBuilderProps> = (props) => {
  const {component} = props;

  return (
    <Stack spacing={4} py={2}>
      {
        component
          ? (
            <Stack>
              {
                component
                .components
                .map(component => mapPageBuilder(component))
              }
            </Stack>
          )
          : null
      }
    </Stack>
  );
};

const mapPageBuilder = (component: (SearchComponentModel | EmailComponentModel | TitledComponentModel | StyledBodyComponentModel)) => {
  let comp = <div/>;

  switch (component.type) {
    case 'search':
      comp =
        <PageDirectorySearchSection
          title={component.content.title} // TODO maybe just pass the components casted to the right models instead?
          description={component.content.description}
        />;
      break;
    case 'email':
      comp =
        <PageEmailSubscribeSection title={component.content.title}
                                   description={component.content.description}
                                   emailPlaceholder={component.content.emailPlaceholder}
        />;
      break;
    case 'titled':
      comp =
        <PageTitledSection title={component.content.title}
                           description={component.content.description}
        />;
      break;
    case 'body':
      comp =
        <PageStyledBodySection body={component.content.body}
                               variant={component.content.variant}
                               color={component.content.color}
                               textCenterAlign={component.content.textCenterAlign}
        />;
      break;
  }

  return (
    <div key={'page-' + generateUniqueID()}>
      {comp}
    </div>
  );
};

export default PageBuilder;