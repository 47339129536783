import {Stack, Typography} from '@mui/material';
import {Variant} from '@mui/material/styles/createTypography';
import {TypographyPropsVariantOverrides} from '@mui/material/Typography/Typography';
import {OverridableStringUnion} from '@mui/types';
import React, {FC} from 'react';
import {generateUniqueID} from 'web-vitals/dist/modules/lib/generateUniqueID';
import {StyledTypographyProps} from './types';

const StyledTypography: FC<StyledTypographyProps> = (props) => {
  const {children, variant = 'body', color, textCenterAlign, bold} = props;

  // lo for log
  // shift + escape in Brave for task manager

  // TODO should probably use something else other than generateUniqueID
  return (
    <Stack>
      {
        children?.toString()
                .split('\n')
                .map(paragraph =>
                  <Typography variant={mapVariant(variant)}
                              key={`paragraph-${generateUniqueID()}`}
                              color={color}
                              textAlign={textCenterAlign
                                ? 'center'
                                : 'start'}
                              fontWeight={bold
                                ? 600
                                : 300}
                  >
                    {paragraph}
                  </Typography>,
                )
      }
    </Stack>
  );
};

const mapVariant = (variant: string): OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides> => {
  switch (variant) {
    case 'h1':
      return 'h1';
    case 'h2':
      return 'h2';
    case 'h3':
      return 'h3';
    case 'h4':
      return 'h4';
    case 'body':
      return 'body1';
    case 'small':
      return 'caption';
    default:
      return 'body1';
  }
};

export default StyledTypography;