import {initializeApp} from 'firebase/app';
import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore';
import {connectAuthEmulator, getAuth} from 'firebase/auth';
import {connectStorageEmulator, getStorage} from 'firebase/storage';
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

if (window.location.hostname === 'localhost') {
  connectFirestoreEmulator(getFirestore(), 'localhost', Number(process.env.REACT_APP_FIRESTORE_EMULATOR));
  connectStorageEmulator(getStorage(), 'localhost', Number(process.env.REACT_APP_STORAGE_EMULATOR));
  connectAuthEmulator(getAuth(), 'http://localhost:' + Number(process.env.REACT_APP_AUTH_EMULATOR));
  connectFunctionsEmulator(getFunctions(), 'localhost', Number(process.env.REACT_APP_FUNCTION_EMULATOR));
}

export default firebaseApp;