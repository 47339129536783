import {DocumentData, QueryDocumentSnapshot} from 'firebase/firestore';

export function firebaseConverter<T extends object>() {
  return {
    toFirestore(doc: T): DocumentData {
      return doc;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): T {
      return snapshot.data()! as T;
    },
  };
}