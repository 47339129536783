import {useFirestoreDocumentData} from '@react-query-firebase/firestore';
import {doc} from 'firebase/firestore';
import {firebaseDb} from '../../firebase/db';
import {firebaseConverter} from '../../firebase/db/helper';
import {BasicPackageLandingPageModel} from '../../models/BasicPackageLandingPageModel';

export function useLocalMattersData(): BasicPackageLandingPageModel {
  const localMattersRef = doc(firebaseDb, 'local-matters', process.env.REACT_APP_CITY ?? '')
  .withConverter(firebaseConverter<BasicPackageLandingPageModel>());

  return useFirestoreDocumentData(['local-matters-thing'], localMattersRef, {
    subscribe: true,
  }).data!;
}