import {Box, Stack} from '@mui/material';
import React, {FC} from 'react';
import Snail from '../../assets/images/esnail_2x.png';
import LinePattern from '../../assets/images/line-pattern-transparent.png';
import Input from '../../components/Input';
import StyledTypography from '../../components/StyledTypography';

export interface PageEmailSubscribeSectionProps {
  title: string;
  description: string;
  emailPlaceholder?: string;
}

const PageEmailSubscribeSection: FC<PageEmailSubscribeSectionProps> = (props) => {
  const {title, description, emailPlaceholder} = props;

  return (
    <Stack direction={'row'} sx={{
      backgroundColor: '#cb2327',
      padding: 1,
    }}
    >
      <Box width={1} display={'flex'} alignItems={'center'} justifyContent={'center'}
           sx={{
             backgroundImage: `url(${LinePattern})`,
             // backgroundRepeat: 'no-repeat',
             // backgroundPosition: '0',
             // top: '90%',
             // position: 'absolute',
           }}
      >


        {/* TODO remake project in a separate repo probably? */}
        {/* TODO actually nah, don't need to make new project, just start using PRs and no squash. Def pile stuff in a legacy folder or something though */}
        {/* TODO if i keep material ui components only within design system, then i can refactor the UI kit later on */}


        <img src={Snail} height={200}/>
      </Box>
      <Stack width={1} height={'300px'} sx={{backgroundColor: 'white', padding: 2}} spacing={1}>
        {/* TODO goes to a dialog to slow down people that could spam enter their email */}
        <StyledTypography variant={'h4'}>
          {title}
        </StyledTypography>
        <Stack>
          <StyledTypography>
            {description}
          </StyledTypography>
          {/*<StyledTypography variant={'small'}>*/}
          {/*  You know, the important stuff*/}
          {/*</StyledTypography>*/}
        </Stack>
        <Input label={emailPlaceholder
          ? emailPlaceholder
          : 'Email'}
        />
      </Stack>
    </Stack>
  );
};

export default PageEmailSubscribeSection;