import {Delete, KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import {Box, Button, IconButton, MenuItem, Select, SelectChangeEvent, Stack} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import {useFirestoreDocumentData, useFirestoreDocumentMutation} from '@react-query-firebase/firestore';
import {doc} from 'firebase/firestore';
import React, {FC, useContext, useEffect, useState} from 'react';
import {generateUniqueID} from 'web-vitals/dist/modules/lib/generateUniqueID';
import EllipsisTypography from '../../components/EllipsisTypography';
import Input from '../../components/Input';
import StyledTabs from '../../components/StyledTabs';
import firebaseAuthContext from '../../contexts/FirebaseAuthContext';
import PageDirectorySearchSection from '../../features/page-builder/page-directory-search-section';
import PageEmailSubscribeSection from '../../features/page-builder/page-email-subscribe-section';
import PageStyledBodySection from '../../features/page-builder/page-styled-body-section';
import PageTitledSection from '../../features/page-builder/page-titled-section';
import {firebaseDb} from '../../firebase/db';
import {firebaseConverter} from '../../firebase/db/helper';
import {
  BasicPackageLandingPageModel,
  EmailComponentModel,
  SearchComponentModel, StyledBodyComponentModel, TitledComponentModel,
} from '../../models/BasicPackageLandingPageModel';
import {AdminBasicPackageProps} from './types';

const AdminBasicPackage: FC<AdminBasicPackageProps> = () => {
  const {claims} = useContext(firebaseAuthContext);
  const city = claims?.['city'];

  return (
    // <Box sx={{display: 'flex', backgroundColor: 'blue'}}>
    //   <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
    //     <Toolbar>
    //       <Typography noWrap component="div">
    //         Clipped drawer
    //       </Typography>
    //     </Toolbar>
    //   </AppBar>
    //   {/*<Drawer sx={{*/}
    //   {/*  // width: 250,*/}
    //   {/*  // flexShrink: 0,*/}
    //   {/*  // [`& .MuiDrawer-paper`]: {width: 250, boxSizing: 'border-box'},*/}
    //   {/*}}*/}
    //   {/*        variant="permanent"*/}
    //   {/*>*/}
    //   {/*  <Toolbar sx={{height: (theme) => 1}}/>*/}
    //   {/*  <List>*/}
    //   {/*    <ListItem>*/}
    //   {/*      Test*/}
    //   {/*    </ListItem>*/}
    //   {/*    <ListItem>*/}
    //   {/*      Test 2*/}
    //   {/*    </ListItem>*/}
    //   {/*    <ListItem>*/}
    //   {/*      Test 3*/}
    //   {/*    </ListItem>*/}
    //   {/*  </List>*/}
    //   {/*</Drawer>*/}
    //   {/*test*/}
    // </Box>
    <StyledTabs tabs={['Home Page', 'Why Local Matters Page', 'About Page']}>
      <LandingPageEditTab city={city}/>
      <LocalMattersPageEditTab city={city}/>
      <AboutPageEditTab city={city}/>
    </StyledTabs>
  );
};

export interface CityProp {
  city: string;
}

function LandingPageEditTab({city}: CityProp) {
  const landingRef = doc(firebaseDb, 'landing', city ?? '')
  .withConverter(firebaseConverter<BasicPackageLandingPageModel>());

  const mutation = useFirestoreDocumentMutation(landingRef, {
    merge: true,
  });

  const landingData = useFirestoreDocumentData(['landing-data'], landingRef, {
    subscribe: true,
  }, {
    onSuccess(landing) {
      if (landing) {
        setData(landing);
      }
    },
  });

  const [data, setData] = useState<BasicPackageLandingPageModel>({
    components: [],
  });
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (landingData.data) {
      setData(landingData.data);
    }
  }, []);

  const handleAddCallback = (model: (SearchComponentModel | EmailComponentModel | TitledComponentModel | StyledBodyComponentModel)) => {
    const componentModel = {...model, position: data.components.length};
    setData({
      components: [...data.components, componentModel],
    });
    setIsChanged(true);
  };

  const removeSection = (name: string) => {
    let components = data.components.filter(f => f.name !== name);
    for (let i = 0; i < components.length; i++) {
      components[i].position = i;
    }
    setData({
      components: components,
    });
    setIsChanged(true);
  };

  const moveSectionUp = (model: (SearchComponentModel | EmailComponentModel | TitledComponentModel | StyledBodyComponentModel)) => {
    setData((prev) => {
      const index = prev.components.findIndex(component => component.name === model.name);

      const components = [...prev.components];
      const tempPosition = components[index].position;
      components[index].position = components[index - 1].position;
      components[index - 1].position = tempPosition;

      return {...prev, components};
    });
    setIsChanged(true);
  };

  const moveSectionDown = (model: (SearchComponentModel | EmailComponentModel | TitledComponentModel | StyledBodyComponentModel)) => {
    setData((prev) => {
      const index = prev.components.findIndex(component => component.id === model.id);

      const components = [...prev.components];
      const tempPosition = components[index].position;
      components[index].position = components[index + 1].position;
      components[index + 1].position = tempPosition;

      return {...prev, components};
    });
    setIsChanged(true);
  };

  const save = () => {
    mutation.mutate({
      components: data.components,
    });
    setIsChanged(false);
  };

  return (
    <Grid2 container spacing={1}>
      <Grid2 mobile={12} tablet={5} laptop={3}>
        <Stack>
          {
            data.components.sort((prev, curr) =>
              prev.position < curr.position
                ? -1
                : 1,
            )
                .map(component =>
                  <Box key={'sections-' + generateUniqueID()} sx={{position: 'relative'}}>
                    <EllipsisTypography content={component.name} characters={25}/>
                    <Stack direction={'row'} sx={{position: 'absolute', top: 0, right: 0}}>
                      <IconButton size={'small'}
                                  sx={{height: 20, width: 20}}
                                  onClick={() => moveSectionUp(component)}
                                  disabled={component.position === 0}
                      >
                        <KeyboardArrowUp/>
                      </IconButton>
                      <IconButton size={'small'}
                                  sx={{height: 20, width: 20}}
                                  onClick={() => moveSectionDown(component)}
                                  disabled={component.position === data.components.length - 1}
                      >
                        <KeyboardArrowDown/>
                      </IconButton>
                      {/*<IconButton size={'small'} sx={{height: 20, width: 20}}>*/}
                      {/*  <Edit/>*/}
                      {/*</IconButton>*/}
                      <IconButton onClick={() => removeSection(component.name)}
                                  size={'small'}
                                  sx={{height: 20, width: 20}}
                      >
                        <Delete/>
                      </IconButton>
                    </Stack>
                  </Box>,
                )
          }
          <Button variant={'contained'} onClick={save} disabled={!isChanged}>
            Save
          </Button>
        </Stack>
      </Grid2>
      <Grid2 mobile={12} tablet={7} laptop={9}>
        <Stack spacing={2}>
          {
            data.components.sort((prev, curr) =>
              prev.position < curr.position
                ? -1
                : 1,
            )
                .map(component =>
                  <Box key={'page-' + generateUniqueID()}>
                    {mapPageBuilder(component)}
                  </Box>,
                )
          }
          <PageBuilderSelect onAddCallback={handleAddCallback}/>
        </Stack>
      </Grid2>
    </Grid2>
  );
}

function LocalMattersPageEditTab({city}: CityProp) {
  const localMattersRef = doc(firebaseDb, 'local-matters', city ?? '')
  .withConverter(firebaseConverter<BasicPackageLandingPageModel>());

  const mutation = useFirestoreDocumentMutation(localMattersRef, {
    merge: true,
  });

  const localMattersData = useFirestoreDocumentData(['local-matters-data'], localMattersRef, {
    subscribe: true,
  }, {
    onSuccess(landing) {
      if (landing) {
        setData(landing);
      }
    },
  });

  const [data, setData] = useState<BasicPackageLandingPageModel>({
    components: [],
  });
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (localMattersData.data) {
      setData(localMattersData.data);
    }
  }, []);

  const handleAddCallback = (model: (SearchComponentModel | EmailComponentModel | TitledComponentModel | StyledBodyComponentModel)) => {
    const componentModel = {...model, position: data.components.length};
    setData({
      components: [...data.components, componentModel],
    });
    setIsChanged(true);
  };

  const removeSection = (name: string) => {
    let components = data.components.filter(f => f.name !== name);
    for (let i = 0; i < components.length; i++) {
      components[i].position = i;
    }
    setData({
      components: components,
    });
    setIsChanged(true);
  };

  const moveSectionUp = (model: (SearchComponentModel | EmailComponentModel | TitledComponentModel | StyledBodyComponentModel)) => {
    setData((prev) => {
      const index = prev.components.findIndex(component => component.name === model.name);

      const components = [...prev.components];
      const tempPosition = components[index].position;
      components[index].position = components[index - 1].position;
      components[index - 1].position = tempPosition;

      return {...prev, components};
    });
    setIsChanged(true);
  };

  const moveSectionDown = (model: (SearchComponentModel | EmailComponentModel | TitledComponentModel | StyledBodyComponentModel)) => {
    setData((prev) => {
      const index = prev.components.findIndex(component => component.id === model.id);

      const components = [...prev.components];
      const tempPosition = components[index].position;
      components[index].position = components[index + 1].position;
      components[index + 1].position = tempPosition;

      return {...prev, components};
    });
    setIsChanged(true);
  };

  const save = () => {
    mutation.mutate({
      components: data.components,
    });
    setIsChanged(false);
  };

  return (
    <Grid2 container spacing={1}>
      <Grid2 mobile={12} tablet={5} laptop={3}>
        <Stack>
          {
            data.components.sort((prev, curr) =>
              prev.position < curr.position
                ? -1
                : 1,
            )
                .map(component =>
                  <Box key={'sections-' + generateUniqueID()} sx={{position: 'relative'}}>
                    <EllipsisTypography content={component.name} characters={25}/>
                    <Stack direction={'row'} sx={{position: 'absolute', top: 0, right: 0}}>
                      <IconButton size={'small'}
                                  sx={{height: 20, width: 20}}
                                  onClick={() => moveSectionUp(component)}
                                  disabled={component.position === 0}
                      >
                        <KeyboardArrowUp/>
                      </IconButton>
                      <IconButton size={'small'}
                                  sx={{height: 20, width: 20}}
                                  onClick={() => moveSectionDown(component)}
                                  disabled={component.position === data.components.length - 1}
                      >
                        <KeyboardArrowDown/>
                      </IconButton>
                      {/*<IconButton size={'small'} sx={{height: 20, width: 20}}>*/}
                      {/*  <Edit/>*/}
                      {/*</IconButton>*/}
                      <IconButton onClick={() => removeSection(component.name)}
                                  size={'small'}
                                  sx={{height: 20, width: 20}}
                      >
                        <Delete/>
                      </IconButton>
                    </Stack>
                  </Box>,
                )
          }
          <Button variant={'contained'} onClick={save} disabled={!isChanged}>
            Save
          </Button>
        </Stack>
      </Grid2>
      <Grid2 mobile={12} tablet={7} laptop={9}>
        <Stack spacing={2}>
          {
            data.components.sort((prev, curr) =>
              prev.position < curr.position
                ? -1
                : 1,
            )
                .map(component =>
                  <Box key={'page-' + generateUniqueID()}>
                    {mapPageBuilder(component)}
                  </Box>,
                )
          }
          <PageBuilderSelect onAddCallback={handleAddCallback}/>
        </Stack>
      </Grid2>
    </Grid2>
  );
}

function AboutPageEditTab({city}: CityProp) {
  const aboutRef = doc(firebaseDb, 'about', city ?? '')
  .withConverter(firebaseConverter<BasicPackageLandingPageModel>());

  const mutation = useFirestoreDocumentMutation(aboutRef, {
    merge: true,
  });

  const aboutData = useFirestoreDocumentData(['about-data'], aboutRef, {
    subscribe: true,
  }, {
    onSuccess(about) {
      if (about) {
        setData(about);
      }
    },
  });

  const [data, setData] = useState<BasicPackageLandingPageModel>({
    components: [],
  });
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (aboutData.data) {
      setData(aboutData.data);
    }
  }, []);

  const handleAddCallback = (model: (SearchComponentModel | EmailComponentModel | TitledComponentModel | StyledBodyComponentModel)) => {
    const componentModel = {...model, position: data.components.length};
    setData({
      components: [...data.components, componentModel],
    });
    setIsChanged(true);
  };

  const removeSection = (name: string) => {
    let components = data.components.filter(f => f.name !== name);
    for (let i = 0; i < components.length; i++) {
      components[i].position = i;
    }
    setData({
      components: components,
    });
    setIsChanged(true);
  };

  const moveSectionUp = (model: (SearchComponentModel | EmailComponentModel | TitledComponentModel | StyledBodyComponentModel)) => {
    setData((prev) => {
      const index = prev.components.findIndex(component => component.name === model.name);

      const components = [...prev.components];
      const tempPosition = components[index].position;
      components[index].position = components[index - 1].position;
      components[index - 1].position = tempPosition;

      return {...prev, components};
    });
    setIsChanged(true);
  };

  const moveSectionDown = (model: (SearchComponentModel | EmailComponentModel | TitledComponentModel | StyledBodyComponentModel)) => {
    setData((prev) => {
      const index = prev.components.findIndex(component => component.id === model.id);

      const components = [...prev.components];
      const tempPosition = components[index].position;
      components[index].position = components[index + 1].position;
      components[index + 1].position = tempPosition;

      return {...prev, components};
    });
    setIsChanged(true);
  };

  const save = () => {
    mutation.mutate({
      components: data.components,
    });
    setIsChanged(false);
  };

  return (
    <Grid2 container spacing={1}>
      <Grid2 mobile={12} tablet={5} laptop={3}>
        <Stack>
          {
            data.components.sort((prev, curr) =>
              prev.position < curr.position
                ? -1
                : 1,
            )
                .map(component =>
                  <Box key={'sections-' + generateUniqueID()} sx={{position: 'relative'}}>
                    <EllipsisTypography content={component.name} characters={25}/>
                    <Stack direction={'row'} sx={{position: 'absolute', top: 0, right: 0}}>
                      <IconButton size={'small'}
                                  sx={{height: 20, width: 20}}
                                  onClick={() => moveSectionUp(component)}
                                  disabled={component.position === 0}
                      >
                        <KeyboardArrowUp/>
                      </IconButton>
                      <IconButton size={'small'}
                                  sx={{height: 20, width: 20}}
                                  onClick={() => moveSectionDown(component)}
                                  disabled={component.position === data.components.length - 1}
                      >
                        <KeyboardArrowDown/>
                      </IconButton>
                      {/*<IconButton size={'small'} sx={{height: 20, width: 20}}>*/}
                      {/*  <Edit/>*/}
                      {/*</IconButton>*/}
                      <IconButton onClick={() => removeSection(component.name)}
                                  size={'small'}
                                  sx={{height: 20, width: 20}}
                      >
                        <Delete/>
                      </IconButton>
                    </Stack>
                  </Box>,
                )
          }
          <Button variant={'contained'} onClick={save} disabled={!isChanged}>
            Save
          </Button>
        </Stack>
      </Grid2>
      <Grid2 mobile={12} tablet={7} laptop={9}>
        <Stack spacing={2}>
          {
            data.components.sort((prev, curr) =>
              prev.position < curr.position
                ? -1
                : 1,
            )
                .map(component =>
                  <Box key={'page-' + generateUniqueID()}>
                    {mapPageBuilder(component)}
                  </Box>,
                )
          }
          <PageBuilderSelect onAddCallback={handleAddCallback}/>
        </Stack>
      </Grid2>
    </Grid2>
  );
}

interface PageBuilderSelectProps {
  onAddCallback: (model: (SearchComponentModel | EmailComponentModel | TitledComponentModel | StyledBodyComponentModel)) => void;
}

function PageBuilderSelect(props: PageBuilderSelectProps) {
  const {onAddCallback} = props;

  const [componentValue, setComponentValue] = useState<'titled' | 'body' | 'search' | 'email'>('titled');

  const handleComponentValueChange = (event: SelectChangeEvent) => {
    // @ts-ignore
    setComponentValue(event.target.value as string);
  };

  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [body, setBody] = useState('');
  const [emailPlaceholder, setEmailPlaceholder] = useState('');

  const handleAddSection = () => {
    switch (componentValue) {
      case 'titled':
        onAddCallback({
          id: generateUniqueID(),
          type: 'titled',
          position: 0,
          name: name,
          content: {
            title: title,
            description: description,
          },
        });
        break;
      case 'body':
        onAddCallback({
          id: generateUniqueID(),
          type: 'body',
          position: 0,
          name: name,
          content: {
            body: body,
            variant: 'h4',
            color: 'white',
            textCenterAlign: true,
          },
        });
        break;
      case 'search':
        onAddCallback({
          id: generateUniqueID(),
          type: 'search',
          position: 0,
          name: name,
          content: {
            title: title,
            description: description,
          },
        });
        break;
      case 'email':
        onAddCallback({
          id: generateUniqueID(),
          type: 'email',
          position: 0,
          name: name,
          content: {
            title: title,
            description: description,
            emailPlaceholder: emailPlaceholder,
          },
        });
        break;
    }
  };

  return (
    <Stack spacing={1} border={'2px solid #32271C'} borderRadius={2} padding={1}>
      <Select value={componentValue} onChange={handleComponentValueChange} size={'small'}>
        <MenuItem value={'titled'}>Titled Section</MenuItem>
        <MenuItem value={'body'}>Body Section</MenuItem>
        <MenuItem value={'search'}>Search Section</MenuItem>
        <MenuItem value={'email'}>Email Section</MenuItem>
      </Select>
      {
        componentValue === 'titled'
          ? (
            <Stack spacing={1}>
              <Input label={'Section name'} value={name} onChange={value => setName(value.target.value)}/>
              <Input label={'Title'} value={title} onChange={value => setTitle(value.target.value)}/>
              <Input label={'Description'} value={description} onChange={value => setDescription(value.target.value)}/>
            </Stack>
          )
          : componentValue === 'body'
            ? (
              <Stack spacing={1}>
                <Input label={'Section name'} value={name} onChange={value => setName(value.target.value)}/>
                <Input label={'Body'} value={body} onChange={value => setBody(value.target.value)}/>
              </Stack>
            )
            : componentValue === 'search'
              ? (
                <Stack spacing={1}>
                  <Input label={'Section name'} value={name} onChange={value => setName(value.target.value)}/>
                  <Input label={'Title'} value={title} onChange={value => setTitle(value.target.value)}/>
                  <Input label={'Description'}
                         value={description}
                         onChange={value => setDescription(value.target.value)}
                  />
                </Stack>
              )
              : componentValue === 'email'
                ? (
                  <Stack spacing={1}>
                    <Input label={'Section name'} value={name} onChange={value => setName(value.target.value)}/>
                    <Input label={'Title'} value={title} onChange={value => setTitle(value.target.value)}/>
                    <Input label={'Description'}
                           value={description}
                           onChange={value => setDescription(value.target.value)}
                    />
                    <Input label={'Email Placeholder'}
                           value={emailPlaceholder}
                           onChange={value => setEmailPlaceholder(value.target.value)}
                    />
                  </Stack>
                )
                : null
      }
      <Button variant={'contained'} onClick={handleAddSection}>
        Add Section
      </Button>
    </Stack>
  );
}

const mapPageBuilder = (component: (SearchComponentModel | EmailComponentModel | TitledComponentModel | StyledBodyComponentModel)) => {
  let comp = <div/>;

  switch (component.type) {
    case 'search':
      comp =
        <Box>
          <PageDirectorySearchSection
            title={component.content.title} // TODO maybe just pass the components casted to the right models instead?
            description={component.content.description}
          />
        </Box>;
      break;
    case 'email':
      comp =
        <PageEmailSubscribeSection title={component.content.title}
                                   description={component.content.description}
                                   emailPlaceholder={component.content.emailPlaceholder}
        />;
      break;
    case 'titled':
      comp =
        <PageTitledSection title={component.content.title}
                           description={component.content.description}
        />;
      break;
    case 'body':
      comp =
        <PageStyledBodySection body={component.content.body}
                               variant={component.content.variant}
                               color={component.content.color}
                               textCenterAlign={component.content.textCenterAlign}
        />;
      break;
  }

  return (
    <div>
      {comp}
    </div>
  );
};

export default AdminBasicPackage;