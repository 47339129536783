import {Card, Stack} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import StyledTypography from '../../components/StyledTypography';
import {AppRoutes} from '../../routes/routes';
import {AdminPackagesProps} from './types';

const AdminPackages: FC<AdminPackagesProps> = () => {
  return (
    <Grid2 container spacing={4}>
      <Grid2 mobile={4}>
        <Card sx={{
          padding: 2,
          height: 300,
          width: 300,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          borderRadius: 4,
          border: '2px solid black',
          boxShadow: 'none',
          textDecoration: 'none',
        }}
              component={Link}
              to={AppRoutes.AdminBasicPackage}
        >
          <Stack>
            <StyledTypography variant={'h2'} textCenterAlign>
              Basic
            </StyledTypography>
            <StyledTypography textCenterAlign>
              General pages and directory management
            </StyledTypography>
          </Stack>
        </Card>
      </Grid2>
    </Grid2>
  );
};

export default AdminPackages;