import {Stack} from '@mui/material';
import React, {FC} from 'react';
import StyledTypography from '../../components/StyledTypography';

export interface PageTitledSectionProps {
  title: string,
  description?: string
}

const PageTitledSection: FC<PageTitledSectionProps> = (props) => {
  const {title, description} = props;

  return (
    <Stack spacing={2} alignItems={'center'} padding={4}>
      <StyledTypography variant={'h2'}>
        {title}
      </StyledTypography>
      <StyledTypography>
        {
          description
            ? description
            : ''
        }
      </StyledTypography>
    </Stack>
  );
};

export default PageTitledSection;