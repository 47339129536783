export const QK_allProductsData = 'all-product-data';
export const QK_favoriteShopsProductsData = 'favorite-shops-product-data';
export const QK_allShopsData = 'all-shops-data';
export const QK_allBusinessesData = 'all-businesses-data';
export const QK_getSingleShopData = 'shop-data-';
export const QK_getSingleEventData = 'event-data-';
export const QK_getSingleShopEventsData = 'shop-event-data-';
export const QK_allGroupCategoriesData = 'all-group-categories';
export const QK_getSingleGroupCategoryData = 'group-category-data-';
export const QK_getSingleEventManagerData = 'event-manager-data-';
