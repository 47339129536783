import {useFirestoreDocumentData} from '@react-query-firebase/firestore';
import {doc} from 'firebase/firestore';
import {firebaseDb} from '../../firebase/db';
import {firebaseConverter} from '../../firebase/db/helper';
import {BasicPackageLandingPageModel} from '../../models/BasicPackageLandingPageModel';

export function useAboutData(): BasicPackageLandingPageModel {
  const aboutRef = doc(firebaseDb, 'about', process.env.REACT_APP_CITY ?? '')
  .withConverter(firebaseConverter<BasicPackageLandingPageModel>());

  return useFirestoreDocumentData(['about-thing'], aboutRef, {
    subscribe: true,
  }).data!;
}