import {Stack} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, {FC, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Input from '../../components/Input';
import StyledMap from '../../components/StyledMap';
import StyledTypography from '../../components/StyledTypography';
import {BusinessModel} from '../../models/BusinessModel';
import {BusinessFilterList} from './business-filter-list';
import {useDirectoryLogic} from './logic';
import {DirectoryProps} from './types';

const Directory: FC<DirectoryProps> = () => {
  const {state, action} = useDirectoryLogic();

  const location = useLocation();

  const [filteredBusinesses, setFilteredBusinesses] = useState<BusinessModel[] | undefined>();

  useEffect(() => {
    if (location.state) {
      const searchValue = location.state.searchValue;
      action.setSearchValue(searchValue);
    }
  }, []);

  useEffect(() => {
    setFilteredBusinesses(state.businesses.data);
  }, [state.businesses.data]);

  return (
    <Stack>
      <StyledTypography variant={'h2'} textCenterAlign>
        Local Business Directory
      </StyledTypography>
      {/*<StyledMap/>*/}
      <Grid2 container spacing={1} height={'100%'}>
        <Grid2 md={3}>
          <Stack spacing={2}>
            <StyledTypography>
              Filter
            </StyledTypography>
            <Input label={'Search'} onChange={action.handleInputValueChange}/>
          </Stack>
        </Grid2>
        <Grid2 md={9}>
          <BusinessFilterList businesses={filteredBusinesses} searchValue={state.searchValue}/>
        </Grid2>
      </Grid2>
    </Stack>
  );
};

export default Directory;