import {useSnackbar} from 'notistack';
import {useContext, useEffect, useReducer} from 'react';
import {useNavigate} from 'react-router-dom';
import firebaseAuthContext from '../../../contexts/FirebaseAuthContext';
import {AppRoutes} from '../../../routes/routes';

type LoginState = {
  email: string,
  password: string
}

// https://www.sumologic.com/blog/react-hook-typescript/
type LoginAction =
  | { type: 'email_changed', email: string }
  | { type: 'password_changed', password: string }

const loginReducer = (state: LoginState, action: LoginAction) => {
  switch (action.type) {
    case 'email_changed':
      return {
        ...state,
        email: action.email,
      };
    case 'password_changed':
      return {
        ...state,
        password: action.password,
      };
  }
};

export const useSignInPageLogic = () => {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {user, signIn, signInLoading} = useContext(firebaseAuthContext);

  const [state, dispatch] = useReducer(loginReducer, {
    email: '',
    password: '',
  });

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [navigate, user]);

  const setEmail = (email: string) => {
    dispatch({type: 'email_changed', email: email});
  };

  const setPassword = (password: string) => {
    dispatch({type: 'password_changed', password: password});
  };

  const login = () => {
    signIn(state.email, state.password)
    .then(() => {
      enqueueSnackbar('Welcome back!', {variant: 'success'});
      // navigate('/');
      navigate(AppRoutes.Admin);
    });
  };

  return {
    state: {...state, isLoginLoading: signInLoading},
    actions: {setEmail, setPassword, login},
  };
};