import {ChangeEvent, useState} from 'react';
import {useDebounce} from 'use-debounce';
import {useBusinessesData} from '../../hooks/db/useBusinessesData';

export const useDirectoryLogic = () => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const businesses = useBusinessesData();

  const handleInputValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return {
    state: {
      businesses: businesses,
      searchValue: debouncedSearchValue,
    },
    action: {handleInputValueChange, setSearchValue},
  };
};