import React from 'react';
import {createBrowserRouter} from 'react-router-dom';
import AdminAppBar from '../appbars/admin-app-bar';
import MainAppBar from '../appbars/main-app-bar';
import NotFoundPage from '../features/NotFoundPage';
import About from '../pages/about';
import AdminBasicPackage from '../pages/admin-basic-package';
import AdminLanding from '../pages/admin-landing';
import AdminLogin from '../pages/admin-login';
import AdminPackages from '../pages/admin-packages';
import AdminSettings from '../pages/admin-settings';
import Directory from '../pages/directory';
import Landing from '../pages/landing';
import WhyLocalMatters from '../pages/why-local-matters';
import {AppRoutes} from './routes';

export const appRouter = createBrowserRouter([
  {
    path: AppRoutes.Home,
    element: <MainAppBar/>,
    errorElement: <NotFoundPage/>,
    children: [
      {
        index: true,
        element: <Landing/>,
      },
      {
        path: AppRoutes.About,
        element: <About/>,
      },
      {
        path: AppRoutes.WhyLocalMatters,
        element: <WhyLocalMatters/>,
      },
      {
        path: AppRoutes.Directory,
        element: <Directory/>,
      },
      {
        path: AppRoutes.AdminLogin,
        element: <AdminLogin/>,
      },
    ],
  },
  {
    path: AppRoutes.Admin,
    element: <AdminAppBar/>,
    errorElement: <NotFoundPage/>,
    children: [
      {
        index: true,
        element: <AdminLanding/>,
      },
      {
        path: AppRoutes.AdminPackages,
        element: <AdminPackages/>,
      },
      {
        path: AppRoutes.AdminBasicPackage,
        element: <AdminBasicPackage/>,
      },
      {
        path: AppRoutes.AdminSettings,
        element: <AdminSettings/>,
      },
    ],
  },
]);