import {Button, Container, Paper, Stack} from '@mui/material';
import React, {FC} from 'react';
import Input from '../../components/Input';
import StyledTypography from '../../components/StyledTypography';
import {useSignInPageLogic} from '../home/sign-in-page/logic';
import {AdminLoginProps} from './types';

const AdminLogin: FC<AdminLoginProps> = () => {
  const {state, actions} = useSignInPageLogic();

  const shouldButtonBeDisabled = () => {
    return !state.email || !state.password || state.isLoginLoading;
  };

  return (
    <Container maxWidth={'sm'} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Paper sx={{padding: 4, borderRadius: 4, backgroundColor: 'neutral.light', width: 1}}>
        <Stack spacing={2}>
          <Stack>
            <StyledTypography>
              Hey 🐎bor!
            </StyledTypography>
            <StyledTypography variant={'small'}>
              Sign in to your admin account
            </StyledTypography>
          </Stack>
          <Stack spacing={1}>
            <Input label={'Email'}
                   autoFocus
                   value={state.email}
                   onChange={e => actions.setEmail(e.target.value)}
            />
            <Input label={'Password'} type={'password'} value={state.password}
                   onChange={e => actions.setPassword(e.target.value)}
                   onKeyDown={e => {
                     if (e.key === 'Enter') {
                       if (state.email && state.password) {
                         actions.login();
                       }
                     }
                   }}
            />
            <Stack direction={'row'} spacing={1}>
              <Button variant={'contained'}
                      onClick={() => actions.login()}
                      disabled={shouldButtonBeDisabled()}
                      fullWidth
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Container>
  );
};

export default AdminLogin;