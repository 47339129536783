import {Box, Stack, Typography} from '@mui/material';
import React, {FC} from 'react';
import {generateUniqueID} from 'web-vitals/dist/modules/lib/generateUniqueID';
import LinePattern from '../../assets/images/line-pattern-dark.jpg';
import StyledTypography from '../../components/StyledTypography';

export interface PageStyledBodySectionProps {
  body: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'body' | 'small';
  color?: string;
  textCenterAlign?: boolean;
}

const PageStyledBodySection: FC<PageStyledBodySectionProps> = (props) => {
  const {body, variant, color, textCenterAlign} = props;

  return (
    <Box width={1} display={'flex'} alignItems={'center'} justifyContent={'center'}
         sx={{
           backgroundImage: `url(${LinePattern})`,
           backgroundColor: '#1f191a',
         }}
    >
      <Stack spacing={4} padding={8}>
        {/*<StyledTypography variant={'h4'} textCenterAlign color={'white'}>*/}
        {/*  This place is special, it really is. You can find community, raise kids, spend a lifetime and retire here.*/}
        {/*</StyledTypography>*/}
        {/*<StyledTypography variant={'h4'} textCenterAlign color={'white'}>*/}
        {/*  If you’re like us, you’re pretty smitten with Asheville.*/}
        {/*</StyledTypography>*/}
        {/*<StyledTypography variant={'h4'} textCenterAlign color={'white'}>*/}
        {/*  But special can also be fragile. We need YOU to keep our local economy resilient and thriving.*/}
        {/*</StyledTypography>*/}
        {
          body?.split('\n')
              .map(paragraph =>
                <StyledTypography variant={variant}
                                  key={`paragraph-${generateUniqueID()}`}
                                  color={color}
                                  textCenterAlign={textCenterAlign}
                >
                  {paragraph}
                </StyledTypography>,
              )
        }
      </Stack>
    </Box>
  );
};

export default PageStyledBodySection;