import React, {FC, KeyboardEventHandler} from 'react';
import {InputProps} from './types';
import {styled, TextField} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// const Item = styled(Paper)(({theme}) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

const StyledInput = styled(TextField)(({theme}) => ({
  '& label': {
    fontSize: theme.typography.fontSize,
    transform: 'translate(8px, 8px)',
  },
  '& label.Mui-focused': {
    transform: 'translate(14px, -9px) scale(0.75)',
  },
  '& label.MuiFormLabel-filled': {
    transform: 'translate(14px, -9px) scale(0.75)',
  },
  // '& .MuiInput-underline:after': {
  //   // borderBottomColor: 'green',
  // },
  '& .MuiOutlinedInput-root': {
    '& input': {
      padding: theme.spacing(1),
    },
    '& fieldset': {
      fontSize: 16,
    },
    // '&:hover fieldset': {
    //   // borderColor: 'yellow',
    // },
    // '&.Mui-focused fieldset': {
    //   // borderColor: 'green',
    // },
  },
}));

const Input: FC<InputProps> = (props) => {
  const {label, autoFocus, value, onChange, type, fullWidth, onKeyDown, disabled, inputRef, onEnterDown} = props;

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (onKeyDown) {
      onKeyDown(event);
    } else if (onEnterDown && event.key === 'Enter') {
      onEnterDown(value?.toString() ?? '');
    }
  };

  // TODO helper text?
  return (
    <StyledInput label={label}
                 autoFocus={autoFocus}
                 value={value}
                 onChange={onChange}
                 type={type}
                 size={'small'}
                 autoComplete={'off'}
                 fullWidth={fullWidth}
                 onKeyDown={handleKeyDown}
                 disabled={disabled}
                 inputRef={inputRef}
    />
  );
};

export default Input;