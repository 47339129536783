export const AppRoutes = {
  Home: '/',
  About: '/about',
  WhyLocalMatters: '/why-local-matters',
  Directory: '/directory',
  Admin: '/admin',
  AdminLogin: '/admin-login',
  AdminPackages: '/admin/packages',
  AdminBasicPackage: '/admin/packages/basic',
  AdminSettings: '/admin/settings',
};