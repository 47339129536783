import {useFirestoreDocumentData} from '@react-query-firebase/firestore';
import {doc} from 'firebase/firestore';
import {firebaseDb} from '../../firebase/db';
import {firebaseConverter} from '../../firebase/db/helper';
import {BasicPackageLandingPageModel} from '../../models/BasicPackageLandingPageModel';

export function useBasicPackageLandingData(): BasicPackageLandingPageModel {
  const landingRef = doc(firebaseDb, 'landing', process.env.REACT_APP_CITY ?? '')
  .withConverter(firebaseConverter<BasicPackageLandingPageModel>());

  return useFirestoreDocumentData(['landing-thing'], landingRef, {
    subscribe: true,
  }).data!; // TODO this works fine, but start generifying loading and all that and not pass firestore stuff to app code
}

// title: 'Love Asheville.\nKeep It Local.',
// description: 'You add to the unique character of our city. Keeping our vibrant local economy successful is up to you.\nOur job is to connect you to the best, locally owned, independent businesses in our community.\nOur local businesses can use your support now more than ever!',