import React, {FC} from 'react';
import PageBuilder from '../../features/page-builder';
import {useBasicPackageLandingData} from '../../hooks/db/useBasicPackageLandingData';
import {BasicPackageLandingPageModel} from '../../models/BasicPackageLandingPageModel';
import {LandingProps} from './types';

const Landing: FC<LandingProps> = () => {
  const packageData = useBasicPackageLandingData();

  return (
    <PageBuilder component={packageData}/>
    // <Stack justifyContent={'center'} alignItems={'center'}>
    //   <StyledTypography variant={'h2'}>
    //     Local news and events
    //   </StyledTypography>
    // </Stack>
  );
};

interface LandingPageProps {
  data: BasicPackageLandingPageModel;
}

export const LandingPage: FC<LandingPageProps> = (props) => {
  const {data} = props;

  return (
    <PageBuilder component={data}/>
  );
};

export default Landing;