import {Box, Stack, Tab, Tabs} from '@mui/material';
import React, {FC, SyntheticEvent, useState} from 'react';
import {StyledTabsProps} from './types';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

// TODO should the tabs be routable? (changes browser history?) So going back doesn't put you on the first tab all the time
const StyledTabs: FC<StyledTabsProps> = ({tabs, children}) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Stack spacing={1}>
      <Tabs value={tabIndex} onChange={handleTabChange}>
        {
          tabs.map(tab => (
            <Tab key={'tab-' + tab} label={tab}/>
          ))
        }
      </Tabs>
      {
        React.Children.map(children, (child, index) =>
          <TabPanel index={tabIndex} value={index}>
            {child}
          </TabPanel>,
        )
      }
    </Stack>
  );
};

export default StyledTabs;