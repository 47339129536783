import {Box, Stack, Typography} from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

export function NotFoundPage() {
  return (
    <Box height={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Stack alignItems={'center'}>
        <Typography textAlign={'center'} variant={'h2'}>
          Page Not Found!
        </Typography>
        <SentimentDissatisfiedIcon sx={{height: 64, width: 64}}/>
      </Stack>
    </Box>
  );
}

export default NotFoundPage;