import {Typography} from '@mui/material';
import React, {FC} from 'react';
import {AdminSettingsProps} from './types';

const AdminSettings: FC<AdminSettingsProps> = () => {
  return (
    <div>
      AdminSettings
      <Typography>
        What packages should show?
      </Typography>
      <Typography>
        Members/Admin pages? Account management basically.
      </Typography>
    </div>
  );
};

export default AdminSettings;