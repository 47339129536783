import {Box} from '@mui/material';
import React, {FC} from 'react';
import {StyledLinkBoxProps} from './types';

const StyledLinkBox: FC<StyledLinkBoxProps> = (props) => {
  const {action, maxWidth, width, height} = props;

  return (
    <Box onClick={action}
         sx={{
           borderRadius: 2,
           padding: '4px',
           maxWidth: maxWidth,
           width: width,
           height: height,
           // borderWidth: 2,
           // borderColor: 'rgba(0,0,0,0)',
           // borderStyle: 'solid',
           border: '2px solid rgba(0,0,0,0)',
           textDecoration: 'none', // NOTE Need this textDecoration none to remove underline
           ':hover': {
             backgroundColor: '#F2F2F2',
             boxShadow: 2,
             borderColor: 'rgba(0,0,0,0.4)',
             cursor: 'pointer',
           },
         }}
    >
      {props.children}
    </Box>
  );
};

export default StyledLinkBox;