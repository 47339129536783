import {
  useAuthIdToken,
  useAuthSignInWithEmailAndPassword,
  useAuthSignOut,
  useAuthUser,
} from '@react-query-firebase/auth';
import {ParsedToken, User} from 'firebase/auth';
import {useSnackbar} from 'notistack';
import {createContext, useEffect, useState} from 'react';
import {firebaseAuth} from '../firebase/auth';

interface FirebaseAuthContextProps {
  user: User,
  claims: ParsedToken,
  signOut: () => Promise<void>,
  signIn: (email: string, password: string) => Promise<void>,
  signInLoading: boolean
}

const FirebaseAuthContext = createContext<FirebaseAuthContextProps>({
  user: null as unknown as User,
  claims: null as unknown as ParsedToken,
  signOut: () => {
    return new Promise<void>(() => {
    });
  },
  signIn: (email: string, password: string) => {
    return new Promise<void>(() => {
    });
  },
  signInLoading: false,
});

const FirebaseAuthProvider = (props: { children: JSX.Element | JSX.Element[] }) => {
  const {enqueueSnackbar} = useSnackbar();
  const signInMutation = useAuthSignInWithEmailAndPassword(firebaseAuth, {
    onError() {
      enqueueSnackbar('Invalid email/password.', {variant: 'error'});
      setUser(null);
    },

    onSuccess(user) {
      setUser(user.user);
    },
  });
  const signOutMutation = useAuthSignOut(firebaseAuth);
  const user = useAuthUser(['user'], firebaseAuth);
  const token = useAuthIdToken(['token'], firebaseAuth, {
    select(result) {
      if (result) {
        return result.token.claims;
      } else {
        return '';
      }
    },
  });

  const setUser = (user: User | null) => {
    if (user) {
      user.getIdTokenResult(true)
          .then(result => {
            setState({...state, user: user, claims: result.claims});
          });
    } else {
      setState({...state});
    }
  };

  const signOut = (): Promise<void> => {
    signOutMutation.mutate();
    setUser(null);
    return Promise.resolve();
  };

  const signIn = async (email: string, password: string): Promise<void> => {
    return signInMutation.mutate({email: email, password: password});
  };

  let initState = {
    user: user.data as User,
    claims: token.data as ParsedToken,
    signOut: signOut,
    signIn: signIn,
    signInLoading: signInMutation.isLoading, // TODO this is broken i think?
  };

  const [state, setState] = useState(initState);

  // ** This is because of page refresh
  useEffect(() => {
    if (user.isSuccess) {
      setUser(user.data);
    }
  }, [user.data]);

  // console.log('user init ', user);
  // console.log('claims init', token.data);
  // console.log('state user ', state.user);
  // console.log('state claims ', state.claims);

  return (
    <FirebaseAuthContext.Provider value={state}>
      {props.children}
    </FirebaseAuthContext.Provider>
  );
};

export default FirebaseAuthContext;
export {FirebaseAuthProvider};