import {Box, Button, Container, IconButton, Stack, Typography} from '@mui/material';
import React, {FC, useContext} from 'react';
import {Link, Outlet} from 'react-router-dom';
import GoLocalLogo from '../../assets/images/gla-logo.jpg';
import StyledTypography from '../../components/StyledTypography';
import firebaseAuthContext from '../../contexts/FirebaseAuthContext';
import {AppRoutes} from '../../routes/routes';
import {MainAppBarProps} from './types';

const MainAppBar: FC<MainAppBarProps> = () => {
  const {claims} = useContext(firebaseAuthContext);
  const role = claims?.['role'];

  if (!role) {
    return (
      // TODO do i use a Container to limit the appbar width?
      // TODO fix the stupid image fading down thing
      <Stack height={'100vh'}>
        <Stack direction={'row'}
               justifyContent={'space-between'}
        >
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <IconButton sx={{borderRadius: 1}} component={Link} to={'/'}>
              <img src={GoLocalLogo} alt={'GoLocal-icon'}/>
            </IconButton>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Button component={Link} to={AppRoutes.WhyLocalMatters}>
                <Typography textTransform={'none'} sx={{
                  ':hover': {
                    fontWeight: 500,
                  },
                  width: 170,
                }}
                >
                  Why Local Matters
                </Typography>
              </Button>
              <Button component={Link} to={AppRoutes.Directory}>
                <Typography textTransform={'none'} sx={{
                  ':hover': {
                    fontWeight: 500,
                  },
                  width: 90,
                }}
                >
                  Directory
                </Typography>
              </Button>
              {/*<Button component={Link} to={AppRoutes.Home}>*/}
              {/*  <Typography textTransform={'none'} sx={{*/}
              {/*    ':hover': {*/}
              {/*      fontWeight: 500,*/}
              {/*    },*/}
              {/*    width: 120,*/}
              {/*  }}*/}
              {/*  >*/}
              {/*    Local News*/}
              {/*  </Typography>*/}
              {/*</Button>*/}
              <Button component={Link} to={AppRoutes.About}>
                <Typography textTransform={'none'} sx={{
                  ':hover': {
                    fontWeight: 500,
                  },
                  width: 80,
                }}
                >
                  About
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Box display={'flex'} flex={1}>
          <Container maxWidth={'xl'}>
            <Outlet/>
          </Container>
        </Box>
        {/* TODO create Footer comp, just wrap it in the app bar (maybe rename app bars to headers?) */}
        <Box sx={{backgroundColor: '#1f191a'}} minHeight={200} height={200} padding={1}>
          <Stack spacing={2}>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <Button sx={{textTransform: 'none', padding: 0}} component={Link} to={AppRoutes.AdminLogin}>
                <StyledTypography color={'white'} variant={'small'}>
                  Admin Login
                </StyledTypography>
              </Button>
              <StyledTypography color={'white'} variant={'small'}>
                |
              </StyledTypography>
              <StyledTypography color={'white'} variant={'small'}>
                Contact
              </StyledTypography>
            </Stack>
            <Stack>
              <StyledTypography color={'grey'}>
                © 2023 Go Local Asheville LLC | Unchained & Independent
              </StyledTypography>
              <StyledTypography variant={'small'} color={'grey'}>
                Businesses listed on this site are independently owned and operated.
                Go Local Asheville bears no responsibility for the professionalism of listed businesses.
              </StyledTypography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    );
  }

  return (
    // TODO polish after having better POC scope
    <div>
      Shouldn't have a role
    </div>
  );
};

export default MainAppBar;