import {collection, getCountFromServer, query} from 'firebase/firestore';
import {firebaseDb} from '../../firebase/db';
import {businessesCollection} from '../../firebase/db/collections';
import {firebaseConverter} from '../../firebase/db/helper';
import {BusinessModel} from '../../models/BusinessModel';

export async function useBusinessesDataCount(): Promise<number> {
  const businessesRef = query(collection(firebaseDb, businessesCollection)
  .withConverter(firebaseConverter<BusinessModel>()));

  return (await getCountFromServer(businessesRef)).data().count;
}