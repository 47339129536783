import {Stack} from '@mui/material';
import {FC, memo} from 'react';
import {useNavigate} from 'react-router-dom';
import EllipsisTypography from '../../components/EllipsisTypography';
import StyledImage from '../../components/StyledImage';
import StyledLinkBox from '../../components/StyledLinkBox';
import StyledPaginationGrid from '../../components/StyledPaginationGrid';
import StyledTypography from '../../components/StyledTypography';
import {BusinessModel} from '../../models/BusinessModel';

interface BusinessFilterListProps {
  businesses: BusinessModel[] | undefined;
  searchValue: string;
}

export const BusinessFilterList: FC<BusinessFilterListProps> = memo((props) => {
  const {businesses, searchValue} = props;
  const filteredBusinesses = businesses?.filter(business => business.name.toLowerCase()
                                                                    .includes(searchValue.toLowerCase()));
  const navigate = useNavigate();

  const goSomewhere = () => {
    navigate('/');
  };

  return (
    <Stack>
      {/* TODO gridSize is confusing on this level, rename that prop and do some nifty logic in the component instead */}
      <StyledPaginationGrid maxItems={10} gridSize={6}>
        {
          filteredBusinesses?.map(business =>
            <StyledLinkBox key={'business-' + business.name} action={goSomewhere}>
              <Stack direction={'row'} spacing={1}>
                <StyledImage imagePath={business.image} width={188} height={188}/>
                <Stack>
                  {/* TODO add bold type? Or maybe just standardize typography better. Wear the design hat for a day*/}
                  <StyledTypography bold>
                    {business.name}
                  </StyledTypography>
                  {/* TODO make toggle content optional instead */}
                  <EllipsisTypography content={business.description} lines={2}/>
                </Stack>
              </Stack>
            </StyledLinkBox>,
          )
        }
      </StyledPaginationGrid>
    </Stack>
  );
});