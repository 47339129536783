import {createTheme, responsiveFontSizes} from '@mui/material';

export let theme = createTheme({
  shape: {
    // borderRadius: 20,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
  palette: {
    primary: {
      // light: '#3f916b',
      light: '#ADC178',
      // main: '#006340',
      // main: '#830423',
      main: '#5C833E',
      dark: '#003819',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#CFC580',
      main: '#CFC580',
      dark: '#CFC580',
      contrastText: '#000',
    },
    neutral: {
      main: '#e0e0e0',
      light: '#f5f5f5',
    },
    background: {
      // default: '#e0e0e0',
      default: '#F2F4FA',
      paper: '#e0e0e0', // TODO This affects Calendar
    },
  },
  typography: {
    // fontFamily: 'Open+Sans', // The import for this is in index.css
    fontFamily: 'Rubik', // The import for this is in index.css
    // fontSize: 16,
    htmlFontSize: 16,
    allVariants: {
      color: '#32271C',
    },
    // h5: undefined, // these errors out with the responsiveFontSize cause these will be undefined
    // h6: undefined,
    // overline: undefined,
    // subtitle1: undefined,
    // subtitle2: undefined,
    // body2: undefined,
  },
  components: {
    // MuiCard: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#f5f5f5',
    //     },
    //   },
    // },
    // MuiCardActionArea: {
    //   styleOverrides: {
    //     root: {
    //       ':hover': {
    //         backgroundColor: '#e0e0e0',
    //       },
    //     },
    //   },
    // },
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       ':hover': {
    //         backgroundColor: '#CFC580',
    //       },
    //     },
    //   },
    // },
    // MuiIconButton: {
    //   styleOverrides: {
    //     root: {
    //       // color: 'white',
    //       ':hover': {
    //         // backgroundColor: '#CFC580',
    //       },
    //     },
    //   },
    // },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 20,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});

// TODO can this not be declared in line of theme?
// theme.typography.h1 = {
//   fontSize: '1.2rem',
//   '@media (min-width:600px)': {
//     fontSize: '1.5rem',
//   },
//   [theme.breakpoints.up('md')]: {
//     fontSize: '2rem',
//   },
// };

// theme = responsiveFontSizes(theme);

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }

  interface BreakpointOverrides {
    // xs: false; // removes the `xs` breakpoint
    // sm: false;
    // md: false;
    // lg: false;
    // xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h5: false;
    h6: false;
    overline: false;
    subtitle1: false;
    subtitle2: false;
    body2: false;
  }
}