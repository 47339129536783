import {Box, CircularProgress} from '@mui/material';
import React, {FC, useEffect, useState} from 'react';
import {StyledImageProps} from './types';
import {firebaseStorage} from '../../firebase/storage';
import {getBlob, ref} from 'firebase/storage';

const StyledImage: FC<StyledImageProps> = (props) => {
  const {imagePath, height, width, borderRadius} = props;
  const [img, setImg] = useState('');

  useEffect(() => {
    if (imagePath.startsWith('data')) { // TODO remove after everything in prod is ported to Storage
      setImg(imagePath);
    } else {
      const storageRef = ref(firebaseStorage, imagePath);

      getBlob(storageRef)
      .then(img => {
        let parsedImg = URL.createObjectURL(img);
        setImg(parsedImg);
      });
    }
  }, []);

  return (
    img
      ? <img src={img}
             style={{borderRadius: borderRadius ?? 8}}
             height={height ?? 250}
             width={width}
             alt={'img-' + imagePath}
      />
      : (
        <Box height={height ?? 250}
             width={width}
             display={'flex'}
             alignItems={'center'}
             justifyContent={'center'}
        >
          <CircularProgress/>
        </Box>
      )
  );
};

export default StyledImage;