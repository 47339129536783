import {Box, Button, Container, IconButton, Stack, Typography} from '@mui/material';
import React, {FC, useContext} from 'react';
import {Link, Outlet, useNavigate} from 'react-router-dom';
import GoLocalLogo from '../../assets/images/gla-logo-small.jpg';
import firebaseAuthContext from '../../contexts/FirebaseAuthContext';
import {AppRoutes} from '../../routes/routes';
import {AdminAppBarProps} from './types';

const AdminAppBar: FC<AdminAppBarProps> = () => {
  const {claims, signOut} = useContext(firebaseAuthContext);
  const role = claims?.['role'];

  const navigate = useNavigate();

  const onSignOutPressed = () => {
    signOut()
    .then(() => navigate(AppRoutes.Home));
  };

  if (!role) {
    return (
      <div>
        App page loading
      </div>
    );
  } else if (role === 'admin') {
    return (
      <Stack>
        <Stack direction={'row'}
               sx={{backgroundColor: '#F2F2F2'}}
               justifyContent={'space-between'}
        >
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <IconButton sx={{borderRadius: 1}} component={Link} to={AppRoutes.Admin}>
              <img src={GoLocalLogo} alt={'GoLocal-icon'}/>
            </IconButton>
            <Button sx={{borderRadius: 1, textTransform: 'none'}} component={Link} to={AppRoutes.AdminPackages}>
              <Typography fontSize={16} fontWeight={500}>
                {'Packages'}
              </Typography>
            </Button>
          </Stack>
          <Stack direction={'row'} spacing={2} alignItems={'center'} paddingTop={1}>
            <Button sx={{borderRadius: 1, textTransform: 'none'}} component={Link} to={AppRoutes.AdminSettings}>
              <Typography fontSize={16} fontWeight={500}>
                {'Settings'}
              </Typography>
            </Button>
            <Button sx={{borderRadius: 1, textTransform: 'none'}} onClick={onSignOutPressed}>
              <Typography fontSize={16} fontWeight={500}>
                {'Sign out'}
              </Typography>
            </Button>
          </Stack>
        </Stack>
        <Box display={'flex'} flex={1}>
          <Container maxWidth={'xl'}>
            <Outlet/>
          </Container>
        </Box>
      </Stack>
      // <Stack>
      //   <AppBar position={'fixed'} sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
      //     <Toolbar color={'white'}>
      //       <IconButton sx={{borderRadius: 1}} component={Link} to={AppRoutes.Admin}>
      //         <img src={GoLocalLogo} alt={'GoLocal-icon'}/>
      //       </IconButton>
      //       {/*<Button sx={{borderRadius: 1, textTransform: 'none'}} onClick={onSignOutPressed}>*/}
      //       {/*  <Typography fontSize={16} fontWeight={500}>*/}
      //       {/*    {'Sign out'}*/}
      //       {/*  </Typography>*/}
      //       {/*</Button>*/}
      //       <Box flexGrow={1}/>
      //       <IconButton sx={{borderRadius: 1}} onClick={onSignOutPressed}>
      //         <LogoutIcon/>
      //         {/*<Typography fontSize={16} fontWeight={500}>*/}
      //         {/*  {'Sign out'}*/}
      //         {/*</Typography>*/}
      //       </IconButton>
      //     </Toolbar>
      //   </AppBar>
      //   <Box display={'flex'} flex={1}>
      //     <Outlet/>
      //   </Box>
      // </Stack>
    );
  }

  return (
    <div>
      Check why this would even happen
    </div>
  );
};

export default AdminAppBar;