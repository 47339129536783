import {useFirestoreQueryData} from '@react-query-firebase/firestore';
import {collection, FirestoreError, query} from 'firebase/firestore';
import {UseQueryResult} from 'react-query';
import {firebaseDb} from '../../firebase/db';
import {businessesCollection} from '../../firebase/db/collections';
import {firebaseConverter} from '../../firebase/db/helper';
import {QK_allBusinessesData} from '../../firebase/db/query-keys';
import {BusinessModel} from '../../models/BusinessModel';

export function useBusinessesData(): UseQueryResult<(BusinessModel)[], FirestoreError> {
  const businessesRef = query(collection(firebaseDb, businessesCollection)
  .withConverter(firebaseConverter<BusinessModel>()));

  return useFirestoreQueryData([QK_allBusinessesData], businessesRef, {
    idField: 'id',
  });
}