import {Stack} from '@mui/material';
import React, {FC} from 'react';
import DowntownAsheville from '../../assets/images/Mesk_Downtown_Asheville-for-web-1024x448.jpg';
import StyledTypography from '../../components/StyledTypography';
import PageBuilder from '../../features/page-builder';
import {useLocalMattersData} from '../../hooks/db/useLocalMattersData';
import {WhyLocalMattersProps} from './types';

const WhyLocalMatters: FC<WhyLocalMattersProps> = () => {
  const pageData = useLocalMattersData();

  return (
    <Stack>
      <PageBuilder component={pageData}/>
      <Stack direction={'row'}>
        <Stack spacing={4} padding={4}>
          <StyledTypography>
            Local independent businesses are the backbone of Asheville’s economy.
          </StyledTypography>
          <StyledTypography variant={'small'}>
            They are better positioned to respond to the needs of our community, our cultural organizations, and
            nonprofits, and are inherently tied to Asheville’s future.
          </StyledTypography>
        </Stack>
        <Stack padding={4} alignItems={'center'}>
          <img src={DowntownAsheville} width={590}/>
          <StyledTypography variant={'small'}>
            Photo by Jennifer Mesk Photography
          </StyledTypography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default WhyLocalMatters;