import {Card, Stack} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, {FC, useEffect, useState} from 'react';
import StyledTypography from '../../components/StyledTypography';
import {useBusinessesDataCount} from '../../hooks/db/useBusinessesDataCount';
import {AdminLandingProps} from './types';

const AdminLanding: FC<AdminLandingProps> = () => {
  const businessCountAsync = useBusinessesDataCount();
  const [businessCount, setBusinessCount] = useState(-1);

  useEffect(() => {
    businessCountAsync.then(result => setBusinessCount(result));
  }, [businessCountAsync]);

  return (
    <Grid2 container>
      <Grid2>
        {
          businessCount < 0
            ? null
            : <Card sx={{
              padding: 2,
              height: 300,
              width: 300,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              borderRadius: 4,
              border: '2px solid black',
              boxShadow: 'none',
              textDecoration: 'none',
            }}
            >
              <Stack>
                <StyledTypography>
                  # of Businesses
                </StyledTypography>
                <StyledTypography variant={'h2'} textCenterAlign>
                  {businessCount}
                </StyledTypography>
              </Stack>
            </Card>
        }
      </Grid2>
    </Grid2>
  );
};

export default AdminLanding;